import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  hasError: false,
  message: "Something went wrong",
  redirectUrl: null,
}

const bookingErrorsSlice = createSlice({
  name: "bookingErrors",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.hasError = action.payload.hasError
      state.message = action.payload.message
        ? action.payload.message
        : initialState.message
      state.redirectUrl = action.payload.redirectUrl
        ? action.payload.redirectUrl
        : initialState.redirectUrl
    },
    resetState: (state) => {
      state.hasError = initialState.hasError
      state.message = initialState.message
      state.redirectUrl = initialState.redirectUrl
    },
  },
})

export default bookingErrorsSlice
export const bookingErrorsActions = bookingErrorsSlice.actions
