import { createSlice } from "@reduxjs/toolkit"

const globalLoaderSlice = createSlice({
  name: "globalLoader",
  initialState: { loading: false },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export default globalLoaderSlice
export const globalLoaderActions = globalLoaderSlice.actions
