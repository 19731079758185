import React from "react"
import "./assets/styles/global.scss"
import { StripeContext } from "./src/context/StripeContext"
import { AppointmentContext } from "./src/context/AppointmentContext"
import CookieConsent from "react-cookie-consent"
// import { TrackJS } from "trackjs"
import { Provider } from "react-redux"
import store from "./src/new-booking-flow/store/store"
export { wrapPageElement } from "./gatsby-ssr"

// TrackJS.install({
//   token: "f4ed870fad1c4606afc257e905d31983",
// })
// TrackJS.track("Testing TrackJS!")

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <AppointmentContext>
      <StripeContext>{element}</StripeContext>
    </AppointmentContext>
  </Provider>
)

// export const onRouteUpdate = function (_, pluginOptions) {
//   if (
//     process.env.NODE_ENV === "production" ||
//     pluginOptions.includeInDevelopment
//   ) {
//     const timeout = setTimeout(function () {
//       window.dataLayer.push({
//         event: "gatsby-route-change",
//       })
//     }, 50)

//     clearTimeout(timeout)
//   }
// }

export const onRouteUpdate = ({ location, prevLocation }) => {
  const prevPath = prevLocation ? prevLocation.pathname : "/"

  if (location?.pathname === "/bookings/") {
    sessionStorage.setItem("reachedPageBy", prevPath)

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "reach_booking_page_by",
      previous_url: prevPath,
    })
  }

  // if (location?.pathname.includes("/bookings")) {
  //   sessionStorage.setItem("reachedBookingPageBy", prevPath)
  // }

  if (location?.pathname.includes("/bookings")) {
    sessionStorage.setItem("reachedBookingPageByNewFlow", prevPath)
  }
}

// export const onClientEntry = () => {
//   window.addEventListener("load", () => {
//     if (typeof CookieConsent === "undefined") return

//     CookieConsent.
//   })
// }
