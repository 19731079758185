import { createSlice } from "@reduxjs/toolkit"
import collaborativeServices from "./collaborativeServices.json"
// import { current } from "@reduxjs/toolkit"

export const bookingFlowInitialState = {
  selectedCategory: { id: 0, name: "" },
  selectedService: { id: 0, name: "", price: 0, providers: [], duration: 0 },
  selectedSlot: { doctorId: 0, date: "", from: "", to: "" },
  addons: { available: [], selected: { id: 0, name: "", price: 0 } },
  totalPrice: 0,
  lastCompletedStep: 0,
  bookedAppointmentId: 0,
  //collab ==================>
  simpleServices: [
    { id: 0, name: "", price: 0, providers: [], duration: 0 },
    { id: 0, name: "", price: 0, providers: [], duration: 0 },
  ],
  collaborativeSelectedSlot: {
    doctor1: { id: "", simpleServiceId: "" },
    doctor2: { id: "", simpleServiceId: "" },
  },
  //collab ==================>
  emptyFields: false,
}

const bookingFlowSlice = createSlice({
  name: "bookingFlow",
  initialState: bookingFlowInitialState,
  reducers: {
    setCategory: (state, action) => {
      state.selectedSlot = { doctorId: 0, date: "", from: "", to: "" }
      state.selectedService = bookingFlowInitialState.selectedService
      state.addons = bookingFlowInitialState.addons
      //collab logic ==================>
      state.simpleServices = bookingFlowInitialState.simpleServices
      state.collaborativeSelectedSlot =
        bookingFlowInitialState.collaborativeSelectedSlot
      //collab logic ==================>
      //reset data when category changes

      state.selectedCategory.id = action.payload.id
      state.selectedCategory.name = action.payload.name
    },
    setService(state, action) {
      state.selectedSlot = { doctorId: 0, date: "", from: "", to: "" }
      state.addons = bookingFlowInitialState.addons
      //collab logic ==================>
      state.simpleServices = bookingFlowInitialState.simpleServices
      state.collaborativeSelectedSlot =
        bookingFlowInitialState.collaborativeSelectedSlot
      //collab logic ==================>

      //reset date when service changes
      state.selectedService.id = action.payload.id
      state.selectedService.name = action.payload.name
      state.selectedService.price = action.payload.price
      state.selectedService.providers = action.payload.providers
      state.selectedService.duration = action.payload.duration
      state.totalPrice = action.payload.price

      const collaborativeService = collaborativeServices.find(
        (service) => service?.id === action?.payload.id
      )

      if (collaborativeService)
        state.simpleServices = collaborativeService.services
    },
    setLastCompletedStep(state, action) {
      state.lastCompletedStep = action.payload
    },
    setSelectedSlot(state, action) {
      const doctorId = Number(action.payload.slot.doctorId)

      //collab logic ==================>
      if (state.simpleServices) {
        let doctor1SimpleServiceId = 0
        //first doctor
        for (let i = 0; i < state.simpleServices.length; i++) {
          if (doctor1SimpleServiceId) break
          const service = state.simpleServices[i]
          if (service.providers.includes(doctorId)) {
            doctor1SimpleServiceId = service.id
            break
          }
        }

        state.collaborativeSelectedSlot.doctor1.id = doctorId.toString()
        state.collaborativeSelectedSlot.doctor1.simpleServiceId =
          doctor1SimpleServiceId

        //second doctor
        let secondDoctor = null

        for (let i = 0; i < action.payload.availabilitiesForToday.length; i++) {
          if (secondDoctor) break

          const doctor = action.payload.availabilitiesForToday[i]

          // console.log(doctor, "doctor log")
          // console.log(
          //   doctor.slots.find(
          //     (slot) => (slot.from === action.payload.slot.from) !== undefined
          //   )
          // )

          if (
            Number(doctor.doctorId) !== Number(doctorId) &&
            doctor.serviceId !== doctor1SimpleServiceId
          ) {
            const doctorAvailableSlotsArray = doctor.slots.map(
              (slot) => slot.from
            )

            if (
              doctorAvailableSlotsArray?.includes(action?.payload?.slot?.from)
            ) {
              secondDoctor = doctor
              break
            }
          }
        }

        if (secondDoctor) {
          state.collaborativeSelectedSlot.doctor2.id = secondDoctor.doctorId
          state.collaborativeSelectedSlot.doctor2.simpleServiceId =
            secondDoctor.serviceId
        }
      }
      //collab logic ==================>
      state.selectedSlot.doctorId = Number(action.payload.slot.doctorId)
      state.selectedSlot.date = action.payload.slot.date
      state.selectedSlot.from = action.payload.slot.from
      state.selectedSlot.to = action.payload.slot.to
    },
    setAvailableAddons(state, action) {
      state.addons.available = action.payload
    },
    setSelectedAddon(state, action) {
      if (state.addons.selected.id) {
        state.totalPrice = state.totalPrice - state.addons.selected.price
        state.addons.selected = bookingFlowInitialState.addons.selected
      } else {
        state.addons.selected = action.payload
        state.totalPrice = state.totalPrice + action.payload.price
      }
    },
    setBookedAppointmentId(state, action) {
      state.bookedAppointmentId = action.payload
    },
    setEmptyFields(state, action) {
      state.emptyFields = action.payload
    },
    resetState(state) {
      state.selectedCategory = bookingFlowInitialState.selectedCategory
      state.selectedService = bookingFlowInitialState.selectedService
      state.selectedSlot = bookingFlowInitialState.selectedSlot
      state.addons = bookingFlowInitialState.addons
      state.totalPrice = bookingFlowInitialState.totalPrice
      state.lastCompletedStep = bookingFlowInitialState.lastCompletedStep
      state.bookedAppointmentId = bookingFlowInitialState.bookedAppointmentId
    },
  },
})

export default bookingFlowSlice
export const bookingFlowActions = bookingFlowSlice.actions
