import { createSlice } from "@reduxjs/toolkit"
import {
  emailValidation,
  phoneValidation,
  nameValidation,
  confirmEmailValidation,
} from "./fieldValidations"

export const customerInitialState = {
  firstName: {
    value: "",
    status: "Please tell us your first name",
  },
  lastName: {
    value: "",
    status: "Please tell us your last name",
  },
  phone: {
    value: "",
    status: "Please tell us your phone number",
  },
  email: {
    value: "",
    status: "Please tell us your email address",
  },
  confirmEmail: {
    value: "",
    status: "Email confirmation doesn't match",
  },
  birthMonth: { value: "" },
  birthDay: { value: "" },
  birthYear: { value: "" },
  details: { value: "" },
  dialCode: { value: "44" },
  countryCode: { value: "gb" },
  firstSubmit: false,
  formSubmited: false,
}

const customerSlice = createSlice({
  name: "customer",
  initialState: customerInitialState,
  reducers: {
    setFirstName(state, action) {
      state.firstName.value = action.payload
      state.firstName.status = nameValidation(action.payload, "first")
    },
    setLastName(state, action) {
      state.lastName.value = action.payload
      state.lastName.status = nameValidation(action.payload, "last")
    },
    setPhone(state, action) {
      state.phone.value = action.payload
      state.phone.status = phoneValidation(action.payload)
    },
    setEmail(state, action) {
      state.email.value = action.payload
      state.email.status = emailValidation(action.payload)
      state.confirmEmail.status = confirmEmailValidation(
        action.payload,
        state.confirmEmail.value
      )
    },
    setConfirmEmail(state, action) {
      state.confirmEmail.value = action.payload
      state.confirmEmail.status = confirmEmailValidation(
        state.email.value,
        action.payload
      )
    },
    setBirthDay(state, action) {
      state.birthDay.value = action.payload
    },
    setBirthMonth(state, action) {
      state.birthMonth.value = action.payload
    },
    setBirthYear(state, action) {
      state.birthYear.value = action.payload
    },
    setDetails(state, action) {
      state.details.value = action.payload
    },
    setDialCode(state, action) {
      state.dialCode.value = action.payload
    },
    setCountryCode(state, action) {
      state.countryCode.value = action.payload
    },
    setFirstSubmit(state) {
      state.firstSubmit = true
    },
    setFormSubmited(state) {
      state.formSubmited = true
    },
    resetState(state) {
      state.firstName = customerInitialState.firstName
      state.lastName = customerInitialState.lastName
      state.phone = customerInitialState.phone
      state.email = customerInitialState.email
      state.confirmEmail = customerInitialState.confirmEmail
      state.birthDay = customerInitialState.birthDay
      state.birthMonth = customerInitialState.birthMonth
      state.birthYear = customerInitialState.birthYear
      state.details = customerInitialState.details
      state.dialCode = customerInitialState.dialCode
      state.countryCode = customerInitialState.countryCode
      state.firstSubmit = customerInitialState.firstSubmit
      state.formSubmited = customerInitialState.formSubmited
    },
  },
})

export default customerSlice
export const customerActions = customerSlice.actions
