import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getAvailableTimeIntervals,
  removeUnavailableDates,
  getUnavailableDates,
  findFirstAvailableDate,
} from "../../services/availabilityServices"
import dayjs from "dayjs"

export const getAvailabilitiesThunk = createAsyncThunk(
  "type/getAvailabilities",
  async (data, { rejectWithValue }) => {
    try {
      const { startDate, endDate, serviceId, providers, duration } = data
      const response = await getAvailableTimeIntervals(
        startDate,
        endDate,
        serviceId,
        providers,
        duration
      )
      return response
    } catch (error) {
      return rejectWithValue(error?.message)
    }
  }
)

const initialState = {
  calendar: {
    currentlySelectedDate: "",
    currentMonth: null,
    firstAvailableDate: null,
    availabilities: [],
    unavailable: [],
    selectedWeek: [],
  },
  loading: false,
  error: null,
}

const bookingCalendarSlice = createSlice({
  name: "bookingCalendar",
  initialState,
  reducers: {
    setCurrentlySelectedDate(state, action) {
      state.calendar.currentlySelectedDate = action.payload
    },
    setFirstAvailableDate(state, action) {
      state.calendar.firstAvailableDate = action.payload
    },
    resetAvailabilities(state, action) {
      state.calendar.availabilities = []
      state.calendar.unavailable = []
    },
    setSelectedWeek(state, action) {
      state.calendar.selectedWeek = action.payload
    },
    resetCalendar(state) {
      state.calendar = initialState.calendar
    },
    resetState(state) {
      state.calendar = initialState.calendar
      state.loading = initialState.loading
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailabilitiesThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAvailabilitiesThunk.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      const availableDates = removeUnavailableDates(action.payload)
      state.calendar.availabilities = availableDates
      state.calendar.unavailable = getUnavailableDates(action.payload)
      const firstAvailableDate = findFirstAvailableDate(availableDates)
      state.calendar.firstAvailableDate = firstAvailableDate
      state.calendar.currentMonth = dayjs(firstAvailableDate).month() + 1
    })
    builder.addCase(getAvailabilitiesThunk.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default bookingCalendarSlice
export const bookingCalendarActions = bookingCalendarSlice.actions
