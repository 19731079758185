import { bookingFlowInitialState } from "../store/slices/bookingFlowSlice"
import { customerInitialState } from "../store/slices/customerSlice"
import { categoriesAndServicesSlicesInitialState } from "../store/slices/categoriesAndServicesSlice"

const isBrowser = typeof window !== "undefined"

export const writeBookingFlowStateToLocalStorage = (state) => {
  if (!isBrowser) return
  return localStorage.setItem("bookingFlowState", JSON.stringify(state))
}

export const populateBookingFlowStateFromLocalStorage = () => {
  if (!isBrowser) return
  const localState = localStorage.getItem("bookingFlowState")
  if (!localState) return bookingFlowInitialState
  return JSON.parse(localState)
}

export const writeCustomerStateToLocalStorage = (state) => {
  if (!isBrowser) return
  return localStorage.setItem("customerState", JSON.stringify(state))
}

export const populateCustomerStateFromLocalStorage = () => {
  if (!isBrowser) return
  const localState = localStorage.getItem("customerState")
  if (!localState) return customerInitialState
  return JSON.parse(localState)
}

export const writeCategoriesAndServicesStateToLocalStorage = (state) => {
  if (!isBrowser) return
  return localStorage.setItem(
    "categoriesAndServicesState",
    JSON.stringify(state)
  )
}

export const populateCategoriesAndServicesStateFromLocalStorage = () => {
  if (!isBrowser) return
  const localState = localStorage.getItem("categoriesAndServicesState")
  if (!localState) return categoriesAndServicesSlicesInitialState
  return JSON.parse(localState)
}
