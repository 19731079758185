import collaborativeServices from "../store/slices/bookingFlowSlice/collaborativeServices"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import minMax from "dayjs/plugin/minMax"
import duration from "dayjs/plugin/duration"
const COMPANY_LOGIN = process.env.GATSBY_SIMPLYBOOK_COMPANY
const API_KEY = process.env.GATSBY_BOOKLY_API_KEY

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)
dayjs.extend(duration)

const getAPIToken = async () => {
  try {
    const headers = {
      accept: "application/json, text/javascript, */*; q=0.01",
      "content-type": "application/json",
      "sec-fetch-mode": "cors",
    }

    const settings = {
      method: "POST",
      headers: headers,
      mode: "cors",
      referrerPolicy: "strict-origin-when-cross-origin",
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "getToken",
        params: [COMPANY_LOGIN, API_KEY],
        id: 1,
      }),
    }

    const response = await fetch(
      "https://user-api.simplybook.me/login",
      settings
    )

    const data = await response.json()

    return data?.result || null
  } catch (error) {
    console.log("getAPIToken error", error)

    return null
  }
}

const mergeResponses = (fArray, sArray, firstServiceId, secondServiceId) => {
  if (!fArray?.length && !sArray?.length) return []

  const result = []

  // Get all unique dates from both arrays
  const allDates = new Set([
    ...fArray.map((day) => day.date),
    ...sArray.map((day) => day.date),
  ])

  allDates.forEach((date) => {
    const fDay = fArray.find((day) => day.date === date)
    const sDay = sArray.find((day) => day.date === date)

    const dayResult = {
      date: date,
      slots: [],
    }

    // If either day is missing, add empty day to result
    if (!fDay || !sDay) {
      result.push(dayResult)
      return
    }

    // Find all matching time slots between any doctors in both services
    const matchingTimeSlots = new Set()

    fDay.slots.forEach((fDoctor) => {
      sDay.slots.forEach((sDoctor) => {
        fDoctor.slots.forEach((fSlot) => {
          sDoctor.slots.forEach((sSlot) => {
            if (fSlot.from === sSlot.from && fSlot.to === sSlot.to) {
              matchingTimeSlots.add(JSON.stringify(fSlot))
            }
          })
        })
      })
    })

    // If no matching slots found, add empty day to result
    if (matchingTimeSlots.size === 0) {
      result.push(dayResult)
      return
    }

    // Process first array doctors
    fDay.slots.forEach((doctor) => {
      const matchingSlots = doctor.slots.filter((slot) =>
        matchingTimeSlots.has(JSON.stringify(slot))
      )

      if (matchingSlots.length > 0) {
        dayResult.slots.push({
          doctorId: doctor.doctorId,
          serviceId: firstServiceId,
          slots: matchingSlots,
        })
      }
    })

    // Process second array doctors
    sDay.slots.forEach((doctor) => {
      const matchingSlots = doctor.slots.filter((slot) =>
        matchingTimeSlots.has(JSON.stringify(slot))
      )

      if (matchingSlots.length > 0) {
        dayResult.slots.push({
          doctorId: doctor.doctorId,
          serviceId: secondServiceId,
          slots: matchingSlots,
        })
      }
    })

    result.push(dayResult)
  })

  return result
}

const getCollaborativeAvailableTimeIntervals = async (
  startDate,
  endDate,
  serviceId,
  providerIds,
  duration,
  token
) => {
  try {
    const data = collaborativeServices

    const serviceData = data.find((service) => service.id === serviceId)

    if (!serviceData) return []

    const firstServiceId = serviceData?.services?.[0]?.id
    const secondServiceId = serviceData?.services?.[1]?.id

    const firstProviderIds = serviceData?.services?.[0]?.providers
    const secondProviderIds = serviceData?.services?.[1]?.providers

    if (
      !firstProviderIds ||
      !secondProviderIds ||
      !firstProviderIds?.length ||
      !secondProviderIds?.length
    )
      return []

    const fResponse = await getSingleAvailableTimeIntervals(
      startDate,
      endDate,
      firstServiceId,
      firstProviderIds,
      duration,
      token
    )

    console.log("fResponse", fResponse)

    const sResponse = await getSingleAvailableTimeIntervals(
      startDate,
      endDate,
      secondServiceId,
      secondProviderIds,
      duration,
      token
    )

    console.log("sResponse", sResponse)

    // merge the two responses
    const result = mergeResponses(
      fResponse,
      sResponse,
      firstServiceId,
      secondServiceId
    )

    console.log("new result", result)

    return result
  } catch (error) {
    throw error
  }
}

const getSingleAvailableTimeIntervals = async (
  startDate,
  endDate,
  serviceId,
  providerIds,
  duration,
  token
) => {
  try {
    const params = [`${startDate}`, `${endDate}`, serviceId, providerIds]

    const headers = {
      accept: "application/json, text/javascript, */*; q=0.01",
      "content-type": "application/json",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site",
      "x-company-login": COMPANY_LOGIN,
      "x-token": token,
    }

    const formData = {
      jsonrpc: "2.0",
      method: "getAvailableTimeIntervals",
      params,
      id: 3,
    }

    const settings = {
      method: "POST",
      headers: headers,
      mode: "cors",
      referrerPolicy: "strict-origin-when-cross-origin",
      credentials: "omit",
      body: JSON.stringify(formData),
    }

    const response = await fetch("https://user-api.simplybook.me/", settings)

    const data = await response.json()

    const dataResultWithRemovedShortTimeSlots =
      removeTimeSlotsThatAreShorterThanDuration(data?.result, duration)

    const result = mapAvailabilities(
      dataResultWithRemovedShortTimeSlots,
      duration
    )

    return result
  } catch (error) {
    console.log("getSingleAvailableTimeIntervals error", error)

    return null
  }
}

export const getAvailableTimeIntervals = async (
  startDate,
  endDate,
  serviceId,
  providerIds,
  duration
) => {
  try {
    const token = await getAPIToken()

    const isCollaborative = checkCollaborativeService(serviceId)

    const result = isCollaborative
      ? await getCollaborativeAvailableTimeIntervals(
          startDate,
          endDate,
          serviceId,
          providerIds,
          duration,
          token
        )
      : await getSingleAvailableTimeIntervals(
          startDate,
          endDate,
          serviceId,
          providerIds,
          duration,
          token
        )
    return result
  } catch (error) {
    throw error
  }
}

const checkCollaborativeService = (serviceId) => {
  const data = collaborativeServices

  const collaborative = data.find((service) => service.id === serviceId)

  return collaborative && collaborative?.id ? true : false
}

const mapAvailabilities = (availabilities, duration) => {
  if (!availabilities) return []
  const availabilitiesData = []

  for (const key in availabilities) {
    const slots = mapAvailableSlots(availabilities[key], duration, key)
    availabilitiesData.push({
      date: key,
      slots,
    })
  }

  return availabilitiesData
}

const mapAvailableSlots = (slots, duration, date) => {
  const doctorSlots = []

  for (const key in slots) {
    // Map only dates that are in the future and today
    const slotDate = dayjs(date).format("YYYY-MM-DD")
    const today = dayjs().format("YYYY-MM-DD")

    if (slots[key]?.length && slotDate >= dayjs().format("YYYY-MM-DD")) {
      const mapedSLots =
        today === slotDate
          ? mapTodayDates(slots[key], duration)
          : mapDates(slots[key], duration)
      // console.log("mapedSLots", mapedSLots)
      const data = {
        doctorId: key,
        slots: mapedSLots,
      }

      doctorSlots.push(data)
    }
  }

  return doctorSlots
}

const mapDates = (slots, duration = 30) => {
  const result = []
  slots.forEach((slot) => {
    let start = new Date(`1970-01-01T${slot.from}Z`)
    const end = new Date(`1970-01-01T${slot.to}Z`)

    while (start < end) {
      const next = new Date(start.getTime() + duration * 60000) // duration in milliseconds
      if (next > end) break

      result.push({
        from: start.toISOString().substr(11, 8),
        to: next.toISOString().substr(11, 8),
      })

      start = next
    }
  })
  return result
}

const mapTodayDates = (slots, duration = 30) => {
  const result = []
  slots.forEach((slot) => {
    let start = new Date(`1970-01-01T${slot.from}Z`)
    const end = new Date(`1970-01-01T${slot.to}Z`)

    while (start < end) {
      const next = new Date(start.getTime() + duration * 60000) // duration in milliseconds
      if (next > end) break

      result.push({
        from: start.toISOString().substr(11, 8),
        to: next.toISOString().substr(11, 8),
      })

      start = next
    }
  })

  // const oneHourAgoTime = dayjs().add(1, "hour").format("HH:mm:ss")
  const oneHourAgoLondonTime = dayjs()
    .tz("Europe/London")
    .add(3, "hour")
    .format("HH:mm:ss")

  const timesArray = result.filter(({ from }) => {
    return from > oneHourAgoLondonTime
  })

  return timesArray
}

const checkTodayTimeSlots = (availability, mode) => {
  //NOTE** checks if today has any time slots that are after the current time,
  //used both to filter the a Available and Uavailable slots in the functions removeUnavailableDates and getUnavailableDates
  //the mode argument is used to determine if we are filtering for available or unavailable slots

  const isToday = dayjs(availability?.date).isSame(dayjs(), "day")
  if (mode === "filterAvailable" && !isToday) return true
  const allslotsToday = []
  availability.slots.forEach((slot) => {
    slot.slots.forEach((slot) => {
      allslotsToday.push(slot)
    })
  })

  const fromTimes = allslotsToday.map((slot) =>
    dayjs(`${availability?.date}T${slot.from}`, "HH:mm:ss")
  )

  const latestTime = dayjs.max(fromTimes)
  const currentTime = dayjs().tz("Europe/London")
  const isAfter = dayjs(latestTime).isAfter(dayjs(currentTime))

  if (isAfter && mode === "filterAvailable") return true
  if (!isAfter && isToday && mode === "filterUnavailable") return true
  return false
}

export const removeUnavailableDates = (availabilities) => {
  const available = availabilities?.filter((availability) => {
    const hasTimeSlots = checkTodayTimeSlots(availability, "filterAvailable")

    return (
      availability?.slots?.length !== 0 &&
      (dayjs(availability?.date).isSame(dayjs(), "day") ||
        dayjs(availability?.date).isAfter(dayjs())) &&
      hasTimeSlots
    )
  })

  return available || []
}

export const getUnavailableDates = (availabilities) => {
  const unavailable = availabilities
    ?.filter((availability) => {
      const noTimeSlots = checkTodayTimeSlots(availability, "filterUnavailable")
      return (
        availability?.slots?.length === 0 ||
        dayjs(availability?.date).isBefore(dayjs(), "day") ||
        noTimeSlots
      )
    })
    ?.map((availability) => availability?.date)
  return unavailable || []
}

const checkFirstTodayTimeSlots = (availability) => {
  const dateToCheck = dayjs(availability?.date)
  const now = dayjs()

  // Check if the date is in the future
  const isInFuture = dateToCheck.isAfter(now)

  if (isInFuture) return true

  // Merge all slots into one array
  const times = availability?.slots?.flatMap((s) => s.slots)

  if (!times?.length) return false

  // const oneHourAgoTime = dayjs().add(1, "hour").format("HH:mm:ss")
  const oneHourAgoLondonTime = dayjs()
    .tz("Europe/London")
    .add(3, "hour")
    .format("HH:mm:ss")

  const timesArray = times.filter(({ from }) => {
    return from > oneHourAgoLondonTime
  })

  return timesArray?.length ? true : false
}

export const findFirstAvailableDate = (availabilities) => {
  const available = availabilities?.find(
    (availability) => availability?.slots?.length !== 0
  )

  return available?.date || null
}

const removeTimeSlotsThatAreShorterThanDuration = (result, duration) => {
  if (!result) return []
  const durationInMs = duration * 60 * 1000
  const resultCopy = JSON.parse(JSON.stringify(result))

  for (const dateObjKey in resultCopy) {
    const dateObj = resultCopy[dateObjKey]
    for (const doctorObjKey in dateObj) {
      const doctorSlotsArray = dateObj[doctorObjKey]
      if (!doctorSlotsArray?.length) continue
      for (let i = 0; i < doctorSlotsArray.length; i++) {
        const slot = doctorSlotsArray[i]
        const [fromHours, fromMinutes, fromSeconds] = slot.from
          .split(":")
          .map(Number)
        const [toHours, toMinutes, toSeconds] = slot.to.split(":").map(Number)
        const fromMs = dayjs
          .duration({
            hours: fromHours,
            minutes: fromMinutes,
            seconds: fromSeconds,
          })
          .asMilliseconds()
        const toMs = dayjs
          .duration({
            hours: toHours,
            minutes: toMinutes,
            seconds: toSeconds,
          })
          .asMilliseconds()
        const timeSlotDifference = toMs - fromMs
        if (timeSlotDifference < durationInMs) {
          doctorSlotsArray.splice(i, 1)
          i--
        }
      }
    }
  }

  return resultCopy || []
}
