const React = require("react")
const { StripeContext } = require("./src/context/StripeContext")
const { AppointmentContext } = require("./src/context/AppointmentContext")
const { Provider } = require("react-redux")
const store = require("/src/new-booking-flow/store/store").default
// const { Partytown } = require("@builder.io/partytown/react")

exports.wrapPageElement = ({ element }) => {
  return (
    <Provider store={store}>
      <AppointmentContext>
        <StripeContext>{element}</StripeContext>
      </AppointmentContext>
    </Provider>
  )
}

exports.onRenderBody = ({ setHeadComponents, setPreBodyComponents }) => {
  setHeadComponents([
    <link
      key="analitics1"
      rel="preconnect"
      href="https://www.google-analytics.com"
    />,
    <link
      key="analitics2"
      rel="dns-prefetch"
      href="https://www.google-analytics.com"
    />,
    <link
      key="gsan-regular"
      rel="preload"
      href="/fonts/GeneralSans-Regular.woff"
      as="font"
      type="font/woff"
      crossOrigin="anonymous"
    />,
    <link
      key="gsan-medium"
      rel="preload"
      href="/fonts/GeneralSans-Medium.woff"
      as="font"
      type="font/woff"
      crossOrigin="anonymous"
    />,
    <link
      key="gsan-bold"
      rel="preload"
      href="/fonts/GeneralSans-Bold.woff"
      as="font"
      type="font/woff"
      crossOrigin="anonymous"
    />,
  ])

  // setPreBodyComponents([
  //   <noscript
  //     key="gtm"
  //     dangerouslySetInnerHTML={{
  //       __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TF7CDZS" height="0" width="0"
  //                     style="display:none;visibility:hidden"></iframe>`,
  //     }}
  //   />,
  // ])
}
