import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getCategoriesWithServices } from "../../services/bookingServices"
import dayjs from "dayjs"

export const getCategoriesWithServicesThunk = createAsyncThunk(
  "type/getCategoriesWithServices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCategoriesWithServices()
      return response
    } catch (error) {
      return rejectWithValue(error?.message)
    }
  }
)

export const categoriesAndServicesSlicesInitialState = {
  categoriesWithServices: [],
  allServices: [],
  providers: [],
  loading: false,
  error: null,
  writtenToLocalStorageInMs: null,
}

const categoriesAndServicesSlice = createSlice({
  name: "categoriesAndServices",
  initialState: categoriesAndServicesSlicesInitialState,
  reducers: {
    setServices(state, action) {
      state.categoriesWithServices = action.payload
    },
    resetState(state) {
      state.categoriesWithServices =
        categoriesAndServicesSlicesInitialState.categoriesWithServices
      state.allServices = categoriesAndServicesSlicesInitialState.allServices
      state.loading = categoriesAndServicesSlicesInitialState.loading
      state.error = categoriesAndServicesSlicesInitialState.error
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesWithServicesThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getCategoriesWithServicesThunk.fulfilled,
      (state, action) => {
        state.error = null
        state.loading = false
        state.categoriesWithServices = action.payload.mappedCategories
        state.allServices = action.payload.allServices
        state.providers = action.payload.providersList
        state.writtenToLocalStorageInMs = dayjs().valueOf()
      }
    )
    builder.addCase(
      getCategoriesWithServicesThunk.rejected,
      (state, action) => {
        state.loading = false
        state.error = action.payload
      }
    )
  },
})

export default categoriesAndServicesSlice
export const categoriesAndServicesActions = categoriesAndServicesSlice.actions
