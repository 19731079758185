import {
  configureStore,
  createListenerMiddleware,
  isAnyOf,
} from "@reduxjs/toolkit"
import {
  writeBookingFlowStateToLocalStorage,
  populateBookingFlowStateFromLocalStorage,
  writeCustomerStateToLocalStorage,
  populateCustomerStateFromLocalStorage,
  // writeCategoriesAndServicesStateToLocalStorage,
  // populateCategoriesAndServicesStateFromLocalStorage,
} from "../services/localStorageServices"
import bookingFlowSlice, { bookingFlowActions } from "./slices/bookingFlowSlice"
import bookingCalendarSlice, {
  bookingCalendarActions,
} from "./slices/bookingCalendarSlice"
import categoriesAndServicesSlice from "./slices/categoriesAndServicesSlice" // getCategoriesWithServicesThunk,
import customerSlice, { customerActions } from "./slices/customerSlice"
import paymentSlice from "./slices/paymentSlice"
import globalLoaderSlice from "./slices/globalLoaderSlice"
import bookingErrorsSlice from "./slices/bookingErrorsSlice"

const bookingFlowMiddleware = createListenerMiddleware()
const customerMiddleware = createListenerMiddleware()
// const categoriesAndServicesMiddleware = createListenerMiddleware()

bookingFlowMiddleware.startListening({
  matcher: isAnyOf(...Object.values(bookingFlowActions)),
  effect: (action, listenerApi) => {
    const bookingFlowState = listenerApi?.getState()?.bookingFlow
    writeBookingFlowStateToLocalStorage(bookingFlowState)

    if (
      action?.type?.includes("setCategory") ||
      action?.type?.includes("setService")
    ) {
      listenerApi.dispatch(bookingCalendarActions.resetCalendar())
    }
  },
})

customerMiddleware.startListening({
  matcher: isAnyOf(...Object.values(customerActions)),
  effect: (_, listenerApi) => {
    const customerState = listenerApi?.getState()?.customer
    writeCustomerStateToLocalStorage(customerState)
  },
})

// categoriesAndServicesMiddleware.startListening({
//   matcher: isAnyOf(getCategoriesWithServicesThunk?.fulfilled),
//   effect: (action, listenerApi) => {
//     console.log(action, "action")

//     const categoriesAndServicesState =
//       listenerApi?.getState()?.categoriesAndServices
//     writeCategoriesAndServicesStateToLocalStorage(categoriesAndServicesState)
//   },
// })

const store = configureStore({
  preloadedState: {
    bookingFlow: populateBookingFlowStateFromLocalStorage(),
    customer: populateCustomerStateFromLocalStorage(),
    // categoriesAndServices: populateCategoriesAndServicesStateFromLocalStorage(),
  },
  reducer: {
    bookingFlow: bookingFlowSlice.reducer,
    bookingCalendar: bookingCalendarSlice.reducer,
    customer: customerSlice.reducer,
    categoriesAndServices: categoriesAndServicesSlice.reducer,
    payment: paymentSlice.reducer,
    globalLoader: globalLoaderSlice.reducer,
    bookingErrors: bookingErrorsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(bookingFlowMiddleware.middleware)
      .concat(customerMiddleware.middleware),
  // .concat(categoriesAndServicesMiddleware.middleware),
})

export default store
