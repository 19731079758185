import axios from "axios"
import categories from "./jsonFIles/categories.json"
import services from "./jsonFIles/services.json"
import providers from "./jsonFIles/providers.json"

export async function getAvailabilities(data) {
  try {
    const response = await axios.post("/api/booking/getAvailabilities", {
      date: data.date,
      provider_id: data.providerId,
      service_id: data.serviceId,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getRequest(data) {
  try {
    const response = await axios.post("/api/booking/getRequest", data)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getCategoriesWithServices() {
  try {
    //HARD CODED ARE USED, BELOW ARE THE ACTUAL REQUESTS
    //WHEN SOMETHING CHANGES IN SIMPLY BOOK USE THE REQUEST TO GENERATE NEW JSON FILES

    // const response = await axios.post("/api/booking/getRequest", {
    //   url: "/categories",
    // })

    // const responseServices = await axios.post("/api/booking/getRequest", {
    //   url: "/services",
    // })

    // const responseProvider = await axios.post("/api/booking/getRequest", {
    //   url: "/providers",
    // })

    // const categories = response?.data?.data?.data
    // const services = responseServices?.data?.data?.data
    // const providers = responseProvider?.data?.data?.data

    // console.log(services, "services")

    if (!categories || !services) return []

    const mappedCategories = categories
      ?.filter((cat) => cat?.name?.toLowerCase() !== "uncategorized")
      ?.map((category) => {
        const categoryServices = services.filter((service) =>
          category.services.includes(service.id)
        )

        return { ...category, services: categoryServices }
      })

    const providersList = providers?.map((provider) => {
      return { id: provider.id, name: provider.name }
    })

    return { mappedCategories, allServices: services, providersList }
  } catch (error) {
    throw error
  }
}

export async function createBookingService(data) {
  try {
    return await axios.post("/api/booking/createBooking", data)
  } catch (error) {
    console.error(error)
  }
}

export async function createClientService(data) {
  try {
    return await axios.post("/api/booking/createClient", data)
  } catch (error) {
    throw error
  }
}

export async function checkForServiceAddons(serviceId) {
  return await getRequest({
    url: `/products?filter[service_id]=${serviceId}`,
  })
}
