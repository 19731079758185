import { createSlice } from "@reduxjs/toolkit"

export const DEPOSIT_VALUE = 50 //does't change

const initialState = {
  selectedPrice: DEPOSIT_VALUE,
  selectedStripePrice: DEPOSIT_VALUE * 100, //add two zeros for stripe
  currency: "gbp", //does't change
  clientSecret: null,
  paymentIntent: null,
  stirpeFormFields: {
    number: {
      empty: true,
      complete: false,
    },
    date: {
      empty: true,
      complete: false,
    },
    cvc: {
      empty: true,
      complete: false,
    },
  },
  firstSubmit: false,
}

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.selectedPrice = Number(action.payload)
      state.selectedStripePrice = Number(action.payload) * 100
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload
    },
    setPaymentIntent: (state, action) => {
      state.paymentIntent = action.payload
    },
    validateStripeFormFields: (state, action) => {
      const { inputName, event } = action.payload
      state.stirpeFormFields[inputName] = {
        empty: event.empty,
        complete: event.complete,
      }
    },
    setFirstSubmit: (state) => {
      state.firstSubmit = true
    },
    resetState: (state) => {
      state.selectedPrice = initialState.selectedPrice
      state.selectedStripePrice = initialState.selectedStripePrice
      state.clientSecret = initialState.clientSecret
      state.paymentIntent = initialState.paymentIntent
      state.stirpeFormFields = initialState.stirpeFormFields
      state.firstSubmit = initialState.firstSubmit
    },
  },
})

export default paymentSlice
export const paymentActions = paymentSlice.actions
